@import "styles/config";

.itemsList :global {

  .content {
    position: relative;
    transition: opacity .3s;
    &.busy {
      pointer-events: none;
      opacity: .3
    }

  }

  .search_query {
    color: #333;
    padding-top: 20px;
  }
  .filters {
    padding-top: 30px;
    padding-bottom: 20px;
    .nb_elem, .sort_order {
      label {
        text-transform: uppercase;
        color: $primary-color;
        padding-right: 15px;
        &.fixlh {
          line-height: 30px;
        }
      }
      > div {
        min-width: 150px;
        @include fixMuiSelect
      }
    }
  }

  .pagger {
    margin: 0 auto;
    text-align: center;
    padding-top: 32px;
    display: table;
    &.infinite {
      button {
        color: white;
      }
    }
  }

  .item {
    border-bottom: 1px solid #EEF3F5;
    padding: 12px;
    transition: box-shadow .4s;
    &:hover {
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
      border-bottom-color: transparent;
      border-radius: 5px;
    }

    .name {
      text-transform: uppercase;
      color: $primary-color;
      font-weight: $bold;
      font-size: rem(18px);
    }

    .author {
      font-weight: $medium;
      color: $ternary-color;
      text-transform: none;
      font-size: rem(16px);
      &::before {
        content: '|';
        padding: 0 12px;
      }
    }


    .right {
      margin-left: auto;
      text-align: right;
      .words {
        letter-spacing: rem(0.48px);
        margin-left: auto;
        & span.title {
          font-weight: $bold;
        }
        & span.value {
          white-space: nowrap;
        }
        padding-right: 16px;
      }

    }

    /* --- RESP ---- */
    &.lg, &.xl {
      .left, .right {
        > div {
          display: inline-block;
        }
      }
    }
    &.xs, &.sm, &.md {
      .author {
        padding: 0;
        &::before {
          display: none
        }
      }
      .right {
        .words {padding-right: 0;}
      }
      &.xs {
        .words {
          text-align: left
        }
        .actions {
          text-align: center;
          padding-top: 15px;

        }
      }
    }

  }

}
