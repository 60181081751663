$primary-color: #00204A;
$secondary-color: #ff790f;
$ternary-color: #00689D;
$grey: #E8F2F2;
$red : #d9000f;

$light: 300;
$normal: 400;
$medium: 500;
$bold: 700;

$html-font-size: 16px;


@import "mixins";
@import "functions";

