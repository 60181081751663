

@use "sass:math";

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color; /* The Fallback */
  background: rgba($color, $opacity);
}

@function stripUnit($value) {
  @return math.div($value, $value * 0 + 1);
}

@function rem($pxValue) {
  @return #{math.div(stripUnit($pxValue), stripUnit($html-font-size))}rem;
}

@mixin lorem {
  font-weight: $light;
}

@mixin fixMuiSelect {
  .MuiSelect-select {
    padding: 6px !important;
    .MuiSelect-icon {
      top: 0 !important;
    }
  }
}

@mixin h1 {
  span {
    font-size: rem(48px);
    line-height: rem(48px);
    font-weight: $normal;
    display:inline-block;
    &::after {
      content: "";
      display: block;
      width: 30px;
      height: 5px;
      background:$primary-color;
      margin-top:20px
    }
    &.spaced {
      padding:38px 0
    }
    &.xs {
      font-size: rem(38px);
      line-height: rem(38px);
    }
  }
}

@mixin h3 {
  span {
    font-size: rem(34px);
    line-height: rem(34px);
    font-weight: $normal;
    display:inline-block;
    color:$ternary-color;
    &::after {
      content: "";
      display: block;
      width: 30px;
      height: 5px;
      background:$ternary-color;
      margin-top:20px
    }
    &.spaced {
      padding:38px 0
    }
    &.xs {
      font-size: rem(28px);
      line-height: rem(28px);
    }
  }
}
